var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SecondsKill_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { click: function($event) {}, "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "秒杀规则", name: "1" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.SeckillRule,
                    rules: _vm.secondsKillRules,
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "秒杀时间", required: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.SeckillRule.timeRange,
                          callback: function($$v) {
                            _vm.$set(_vm.SeckillRule, "timeRange", $$v)
                          },
                          expression: "SeckillRule.timeRange"
                        }
                      }),
                      _c(
                        "el-link",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary", underline: false },
                          on: { click: _vm.getActivityEndTime }
                        },
                        [_vm._v("同步活动时间")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { label: "限购数量", prop: "productNum" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "700px" },
                          attrs: { placeholder: "请输入限购数量" },
                          model: {
                            value: _vm.SeckillRule.productNum,
                            callback: function($$v) {
                              _vm.$set(_vm.SeckillRule, "productNum", $$v)
                            },
                            expression: "SeckillRule.productNum"
                          }
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v(
                              "每人每种商品限购1件，针对单场活动每人最多可购买"
                            )
                          ]),
                          _c("template", { slot: "append" }, [
                            _vm._v("个商品（0为不限制）")
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { label: "好友助力" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.SeckillRule.assistType,
                            callback: function($$v) {
                              _vm.$set(_vm.SeckillRule, "assistType", $$v)
                            },
                            expression: "SeckillRule.assistType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "one" } }, [
                            _vm._v("针对整场活动，仅可助力一款商品")
                          ]),
                          _c("el-radio", { attrs: { label: "more" } }, [
                            _vm._v("针对整场活动，可助力多款商品")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { label: "秒杀群二维码" }
                    },
                    [
                      _c("div", [
                        _c(
                          "label",
                          {
                            staticClass: "el-upload el-upload--picture-card",
                            attrs: { for: "seckill-qrcode" }
                          },
                          [
                            _c("input", {
                              ref: "seckillqrcode",
                              attrs: {
                                type: "file",
                                hidden: "",
                                id: "seckill-qrcode",
                                "data-type": "brandLogo",
                                name: "upload",
                                accept: "image/*"
                              },
                              on: { change: _vm.uploadImage3 }
                            }),
                            _vm.SeckillRule.logo
                              ? _c("img", {
                                  staticStyle: {
                                    width: "142px",
                                    height: "143px"
                                  },
                                  attrs: { src: _vm.SeckillRule.logo }
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "ml10 form_tips" }, [
                        _vm._v("（请注意群二维码失效时间）")
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { label: "秒杀提示" }
                    },
                    [
                      _c("vue-ueditor-wrap", {
                        attrs: { config: _vm.ueditorConfig },
                        model: {
                          value: _vm.SeckillRule.prompt,
                          callback: function($$v) {
                            _vm.$set(_vm.SeckillRule, "prompt", $$v)
                          },
                          expression: "SeckillRule.prompt"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitSecKillRule }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "图片广告", name: "2" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.ToSaveOrEditSecKillAdv(0)
                        }
                      }
                    },
                    [_vm._v("添加图片广告")]
                  ),
                  _c("span", { staticClass: "ml10" }, [
                    _vm._v("（温馨提示：每三个秒杀商品显示一条图片广告）")
                  ])
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.AdvList, border: "", stripe: "" },
                  on: { "selection-change": _vm.handleSelectionChange2 }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "60" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "广告标题" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "logo", label: "广告图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.logo,
                                  expression: "scope.row.logo"
                                }
                              ],
                              staticStyle: {
                                display: "block",
                                width: "50px",
                                height: "50px",
                                margin: "0 10px 0 0"
                              },
                              attrs: { src: scope.row.logo }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "url", label: "链接地址" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "200px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.ToSaveOrEditSecKillAdv(
                                      1,
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-tag",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.ConfirmDelSeckillAdv(
                                      scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.DelSeckillAdv }
                        },
                        [_vm._v("批量删除")]
                      )
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.AdvInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.AdvTotal
                    },
                    on: { "current-change": _vm.handleCurrentChangeAdv }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "秒杀商品", name: "3" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.ToSaveOrEditSecKill(0)
                        }
                      }
                    },
                    [_vm._v("添加商品")]
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入商品名称：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入商品名称"
                        },
                        model: {
                          value: _vm.SeckillInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.SeckillInfo, "name", $$v)
                          },
                          expression: "SeckillInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("品牌商：")]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择品牌商", clearable: "" },
                          model: {
                            value: _vm.SeckillInfo.brandId,
                            callback: function($$v) {
                              _vm.$set(_vm.SeckillInfo, "brandId", $$v)
                            },
                            expression: "SeckillInfo.brandId"
                          }
                        },
                        _vm._l(_vm.BrandList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.SeckillList, border: "", stripe: "" },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "60" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "商品名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cover", label: "商品图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.cover,
                                  expression: "scope.row.cover"
                                }
                              ],
                              staticStyle: {
                                display: "block",
                                width: "50px",
                                height: "50px",
                                margin: "0 10px 0 0"
                              },
                              attrs: { src: scope.row.cover }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandName", label: "所属品牌商" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "seckillPrice", label: "秒杀价（元）" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "salePrice", label: "划线价（元）" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sales", label: "销量" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.sort))]),
                            _c("i", {
                              staticClass: "el-icon-edit-outline",
                              on: {
                                click: function($event) {
                                  return _vm.editSort(scope.row)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == "false"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("已下架")
                                ])
                              : _vm._e(),
                            scope.row.status == "true"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("已上架")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "操作",
                      fixed: "right",
                      width: "200px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == "false"
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.ConfirmSetOnline(
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("上架")]
                                )
                              : _vm._e(),
                            scope.row.status == "true"
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.ConfirmSetOffline(
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("下架")]
                                )
                              : _vm._e(),
                            scope.row.status == "false"
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.ToSaveOrEditSecKill(
                                          1,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            scope.row.status != "true"
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.ConfirmDelSeckillProduct(
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.DelSeckillProduct }
                        },
                        [_vm._v("批量删除")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.SetOnline }
                        },
                        [_vm._v("批量上架")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.SetOffline }
                        },
                        [_vm._v("批量下架")]
                      )
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.SeckillInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.SeckillTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "分享设置", name: "4" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.SeckillShare,
                    rules: _vm.shareRules,
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "分享封面" } },
                    [
                      _c("div", [
                        _c(
                          "label",
                          {
                            staticClass: "el-upload el-upload--picture-card",
                            attrs: { for: "share-logo" }
                          },
                          [
                            _c("input", {
                              ref: "sharelogo",
                              attrs: {
                                type: "file",
                                hidden: "",
                                id: "share-logo",
                                "data-type": "brandLogo",
                                name: "upload",
                                accept: "image/*"
                              },
                              on: { change: _vm.uploadImage4 }
                            }),
                            _vm.SeckillShare.shareLogo
                              ? _c("img", {
                                  staticStyle: {
                                    width: "142px",
                                    height: "143px"
                                  },
                                  attrs: { src: _vm.SeckillShare.shareLogo }
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "ml10 form_tips" }, [
                        _vm._v(
                          "建议尺寸120*120 px，支持JPG，PNG，JPEG格式，小于4M"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享标题", prop: "shareTitle" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写分享标题，最多输入30个字",
                              maxlength: "30",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.SeckillShare.shareTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.SeckillShare, "shareTitle", $$v)
                              },
                              expression: "SeckillShare.shareTitle"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享描述", prop: "shareDesc" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写分享描述，最多输入30个字",
                              maxlength: "30",
                              rows: 3,
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.SeckillShare.shareDesc,
                              callback: function($$v) {
                                _vm.$set(_vm.SeckillShare, "shareDesc", $$v)
                              },
                              expression: "SeckillShare.shareDesc"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitSecKillShare }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "secondsKill_drawer",
          attrs: {
            visible: _vm.addSecondsKillDrawer,
            center: "",
            width: "1600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addSecondsKillDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("秒杀商品信息")]),
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.editGoodForm,
                rules: _vm.addGoodRules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "name", $$v)
                      },
                      expression: "editGoodForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片", prop: "logo" } },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        staticClass: "el-upload el-upload--picture-card",
                        attrs: { for: "brand-logo" }
                      },
                      [
                        _c("input", {
                          ref: "brandlogo",
                          attrs: {
                            type: "file",
                            hidden: "",
                            id: "brand-logo",
                            "data-type": "brandLogo",
                            name: "upload",
                            accept: "image/*"
                          },
                          on: { change: _vm.uploadImage }
                        }),
                        _vm.editGoodForm.cover
                          ? _c("img", {
                              staticStyle: { width: "142px", height: "143px" },
                              attrs: { src: _vm.editGoodForm.cover }
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon"
                            })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "ml10 form_tips" }, [
                    _vm._v("建议尺寸280 * 230px，支持JPG，PNG，JPEG格式")
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属品牌", prop: "brand" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editGoodForm.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.editGoodForm, "brandId", $$v)
                        },
                        expression: "editGoodForm.brandId"
                      }
                    },
                    _vm._l(_vm.BrandList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "秒杀价", prop: "seckillPrice" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入秒杀价", maxlength: "6" },
                    model: {
                      value: _vm.editGoodForm.seckillPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "seckillPrice", $$v)
                      },
                      expression: "editGoodForm.seckillPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "划线价", prop: "salePrice" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入划线价", maxlength: "6" },
                    model: {
                      value: _vm.editGoodForm.salePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "salePrice", $$v)
                      },
                      expression: "editGoodForm.salePrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计件单位", prop: "pieceUnit" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入计件单位",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.pieceUnit,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "pieceUnit", $$v)
                      },
                      expression: "editGoodForm.pieceUnit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计量单位", prop: "measureUnit" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入计量单位",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.measureUnit,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "measureUnit", $$v)
                      },
                      expression: "editGoodForm.measureUnit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "助力人数", prop: "assist" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入助力人数",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.assist,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "assist", $$v)
                      },
                      expression: "editGoodForm.assist"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规格", prop: "specs" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入规格值",
                      maxlength: "30",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.specs,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "specs", $$v)
                      },
                      expression: "editGoodForm.specs"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存", prop: "stock" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入库存",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.stock,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "stock", $$v)
                      },
                      expression: "editGoodForm.stock"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟抢购量", prop: "virtualSales" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入虚拟抢购量",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.virtualSales,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "virtualSales", $$v)
                      },
                      expression: "editGoodForm.virtualSales"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序值", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入排序值",
                      maxlength: "3",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGoodForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "sort", $$v)
                      },
                      expression: "editGoodForm.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品介绍" } },
                [
                  _c("vue-ueditor-wrap", {
                    attrs: { config: _vm.ueditorConfig },
                    model: {
                      value: _vm.editGoodForm.description,
                      callback: function($$v) {
                        _vm.$set(_vm.editGoodForm, "description", $$v)
                      },
                      expression: "editGoodForm.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "name" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.editGoodForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.editGoodForm, "status", $$v)
                        },
                        expression: "editGoodForm.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("上架")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("下架")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitSecKill }
                    },
                    [_vm._v("保 存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.addSecondsKillDrawer = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "添加图片广告",
            visible: _vm.addPicAdvertisingDrawer,
            direction: "rtl",
            size: "500px",
            "custom-class": "secondsKill_drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.addPicAdvertisingDrawer = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.editAdvForm,
                rules: _vm.advRules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入标题名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editAdvForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.editAdvForm, "title", $$v)
                      },
                      expression: "editAdvForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "广告图片", prop: "logo" } },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        staticClass: "el-upload el-upload--picture-card",
                        attrs: { for: "adv-logo" }
                      },
                      [
                        _c("input", {
                          ref: "advlogo",
                          attrs: {
                            type: "file",
                            hidden: "",
                            id: "adv-logo",
                            "data-type": "brandLogo",
                            name: "upload",
                            accept: "image/*"
                          },
                          on: { change: _vm.uploadImage2 }
                        }),
                        _vm.editAdvForm.logo
                          ? _c("img", {
                              staticStyle: { width: "142px", height: "143px" },
                              attrs: { src: _vm.editAdvForm.logo }
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon"
                            })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "ml10 form_tips" }, [
                    _vm._v(
                      "建议尺寸280 * 230px，支持JPG，PNG，JPEG格式，小于4M"
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入链接地址",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editAdvForm.url,
                      callback: function($$v) {
                        _vm.$set(_vm.editAdvForm, "url", $$v)
                      },
                      expression: "editAdvForm.url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序值", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入排序值",
                      maxlength: "3",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editAdvForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.editAdvForm, "sort", $$v)
                      },
                      expression: "editAdvForm.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitSecKillAdv }
                    },
                    [_vm._v("保 存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "排序设置",
            visible: _vm.dialogSortSeen,
            "show-close": false,
            center: "",
            width: "350px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSortSeen = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "40px" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { margin: "0 0 5px" },
                  attrs: { label: "排序", size: "medium" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "tel",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                      placeholder: "数值越大越靠前(范围：0-255)",
                      maxlength: 5
                    },
                    model: {
                      value: _vm.editSortNum,
                      callback: function($$v) {
                        _vm.editSortNum = _vm._n($$v)
                      },
                      expression: "editSortNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogSortSeen = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.saveSort()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }