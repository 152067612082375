<template>
  <div class="SecondsKill_wrapper">
    <el-tabs type="card" v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="秒杀规则" name="1">
        <el-form :model="SeckillRule" :rules="secondsKillRules" label-width="130px">
          <el-form-item label="秒杀时间" required>
            <el-date-picker
              v-model="SeckillRule.timeRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <el-link
              type="primary"
              :underline="false"
              @click="getActivityEndTime"
              class="ml10"
            >同步活动时间</el-link>
          </el-form-item>
          <el-form-item label="限购数量" prop="productNum" style="margin-top:50px">
            <el-input v-model="SeckillRule.productNum" placeholder="请输入限购数量" style="width:700px">
              <template slot="prepend">每人每种商品限购1件，针对单场活动每人最多可购买</template>
              <template slot="append">个商品（0为不限制）</template>
            </el-input>
          </el-form-item>
          <el-form-item label="好友助力" style="margin-top:50px">
            <el-radio-group v-model="SeckillRule.assistType">
              <el-radio label="one">针对整场活动，仅可助力一款商品</el-radio>
              <el-radio label="more">针对整场活动，可助力多款商品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="秒杀群二维码" style="margin-top:50px">
            <div>
              <label for="seckill-qrcode" class="el-upload el-upload--picture-card">
                <input
                  type="file"
                  ref="seckillqrcode"
                  hidden
                  id="seckill-qrcode"
                  data-type="brandLogo"
                  name="upload"
                  accept="image/*"
                  @change="uploadImage3"
                />
                <img
                  v-if="SeckillRule.logo"
                  :src="SeckillRule.logo"
                  style="width:142px;height:143px;"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </label>
            </div>
            <div class="ml10 form_tips">（请注意群二维码失效时间）</div>
          </el-form-item>
          <!-- <el-form-item :required="true" label="秒杀提示" prop="prompt">
            <div class="left_advertising">
              <el-input
                v-model="SeckillRule.prompt"
                placeholder="请填写秒杀提示，最多输入30个字"
                maxlength="30"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>-->
          <el-form-item label="秒杀提示" style="margin-top:50px">
            <vue-ueditor-wrap v-model="SeckillRule.prompt" :config="ueditorConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitSecKillRule">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="图片广告" name="2">
        <div class="select_wrap">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="ToSaveOrEditSecKillAdv(0)"
          >添加图片广告</el-button>
          <span class="ml10">（温馨提示：每三个秒杀商品显示一条图片广告）</span>
        </div>
        <el-table
          class="secondsKill_table"
          :data="AdvList"
          border
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange2"
        >
          <el-table-column type="selection" width="60"></el-table-column>
          <el-table-column prop="title" label="广告标题"></el-table-column>
          <el-table-column prop="logo" label="广告图片">
            <template slot-scope="scope">
              <img
                v-show="scope.row.logo"
                :src="scope.row.logo"
                style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="url" label="链接地址"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column label="操作" fixed="right" width="200px">
            <template slot-scope="scope">
              <el-tag type="success" @click="ToSaveOrEditSecKillAdv(1, scope.row)">编辑</el-tag>
              <el-tag type="danger" @click="ConfirmDelSeckillAdv(scope.row.id)">删除</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="tab-page">
          <div>
            <el-button type="primary" @click="DelSeckillAdv">批量删除</el-button>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChangeAdv"
            :current-page="AdvInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="AdvTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="秒杀商品" name="3">
        <div class="select_wrap">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="ToSaveOrEditSecKill(0)"
          >添加商品</el-button>
          <div>
            <span>请输入商品名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入商品名称"
              v-model="SeckillInfo.name"
            ></el-input>
          </div>
          <div>
            <span>品牌商：</span>
            <el-select v-model="SeckillInfo.brandId" placeholder="请选择品牌商" clearable>
              <el-option
                v-for="item in BrandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
          <el-button type="primary" class="ml10" @click="exportData">导出表格</el-button>
        </div>
        <el-table
          class="secondsKill_table"
          :data="SeckillList"
          border
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="cover" label="商品图片">
            <template slot-scope="scope">
              <img
                v-show="scope.row.cover"
                :src="scope.row.cover"
                style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="brandName" label="所属品牌商"></el-table-column>
          <el-table-column prop="seckillPrice" label="秒杀价（元）"></el-table-column>
          <el-table-column prop="salePrice" label="划线价（元）"></el-table-column>
          <el-table-column prop="stock" label="库存"></el-table-column>
          <el-table-column prop="sales" label="销量"></el-table-column>
          <el-table-column prop="sort" label="排序">
            <template slot-scope="scope">
              <span>{{scope.row.sort}}</span>
              <i class="el-icon-edit-outline" @click="editSort(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.status=='false'">已下架</el-tag>
              <el-tag type="primary" v-if="scope.row.status=='true'">已上架</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" fixed="right" width="200px">
            <template slot-scope="scope">
              <el-tag
                type="primary"
                v-if="scope.row.status=='false'"
                @click="ConfirmSetOnline(scope.row.id)"
              >上架</el-tag>
              <el-tag
                type="danger"
                v-if="scope.row.status=='true'"
                @click="ConfirmSetOffline(scope.row.id)"
              >下架</el-tag>
              <el-tag
                type="success"
                v-if="scope.row.status=='false'"
                @click="ToSaveOrEditSecKill(1,scope.row)"
              >编辑</el-tag>
              <el-tag
                type="danger"
                v-if="scope.row.status!='true'"
                @click="ConfirmDelSeckillProduct(scope.row.id)"
              >删除</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="tab-page">
          <div>
            <el-button type="primary" @click="DelSeckillProduct">批量删除</el-button>
            <el-button type="primary" @click="SetOnline">批量上架</el-button>
            <el-button type="primary" @click="SetOffline">批量下架</el-button>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="SeckillInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="SeckillTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="分享设置" name="4">
        <el-form :model="SeckillShare" :rules="shareRules" label-width="130px">
          <el-form-item :required="true" label="分享封面">
            <div>
              <label for="share-logo" class="el-upload el-upload--picture-card">
                <input
                  type="file"
                  ref="sharelogo"
                  hidden
                  id="share-logo"
                  data-type="brandLogo"
                  name="upload"
                  accept="image/*"
                  @change="uploadImage4"
                />
                <img
                  v-if="SeckillShare.shareLogo"
                  :src="SeckillShare.shareLogo"
                  style="width:142px;height:143px;"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </label>
            </div>
            <div class="ml10 form_tips">建议尺寸120*120 px，支持JPG，PNG，JPEG格式，小于4M</div>
          </el-form-item>
          <el-form-item label="分享标题" prop="shareTitle">
            <div class="left_advertising">
              <el-input
                v-model="SeckillShare.shareTitle"
                placeholder="请填写分享标题，最多输入30个字"
                maxlength="30"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享描述" prop="shareDesc">
            <div class="left_advertising">
              <el-input
                type="textarea"
                placeholder="请填写分享描述，最多输入30个字"
                v-model="SeckillShare.shareDesc"
                maxlength="30"
                :rows="3"
                show-word-limit
                style="width:400px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitSecKillShare">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加商品 -->
    <el-dialog
      :visible.sync="addSecondsKillDrawer"
      center
      width="1600px"
      class="secondsKill_drawer"
    >
      <p class="view_title">秒杀商品信息</p>
      <el-form :model="editGoodForm" :rules="addGoodRules" label-width="100px">
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="editGoodForm.name"
            placeholder="请输入商品名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片" prop="logo">
          <div>
            <label for="brand-logo" class="el-upload el-upload--picture-card">
              <input
                type="file"
                ref="brandlogo"
                hidden
                id="brand-logo"
                data-type="brandLogo"
                name="upload"
                accept="image/*"
                @change="uploadImage"
              />
              <img
                v-if="editGoodForm.cover"
                :src="editGoodForm.cover"
                style="width:142px;height:143px;"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </label>
          </div>
          <div class="ml10 form_tips">建议尺寸280 * 230px，支持JPG，PNG，JPEG格式</div>
        </el-form-item>
        <el-form-item label="所属品牌" prop="brand">
          <el-select v-model="editGoodForm.brandId" placeholder="请选择">
            <el-option v-for="item in BrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="秒杀价" prop="seckillPrice">
          <el-input v-model="editGoodForm.seckillPrice" placeholder="请输入秒杀价" maxlength="6"></el-input>
        </el-form-item>
        <el-form-item label="划线价" prop="salePrice">
          <el-input v-model="editGoodForm.salePrice" placeholder="请输入划线价" maxlength="6"></el-input>
        </el-form-item>
        <el-form-item label="计件单位" prop="pieceUnit">
          <el-input
            v-model="editGoodForm.pieceUnit"
            placeholder="请输入计件单位"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="measureUnit">
          <el-input
            v-model="editGoodForm.measureUnit"
            placeholder="请输入计量单位"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="助力人数" prop="assist">
          <el-input
            v-model="editGoodForm.assist"
            placeholder="请输入助力人数"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="specs">
          <el-input
            v-model="editGoodForm.specs"
            placeholder="请输入规格值"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input v-model="editGoodForm.stock" placeholder="请输入库存" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="虚拟抢购量" prop="virtualSales">
          <el-input
            v-model="editGoodForm.virtualSales"
            placeholder="请输入虚拟抢购量"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model="editGoodForm.sort" placeholder="请输入排序值" maxlength="3" show-word-limit></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品介绍" prop="name">
          <el-input
            v-model="editGoodForm.description"
            placeholder="请输入商品介绍"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>-->
        <el-form-item label="商品介绍">
          <vue-ueditor-wrap v-model="editGoodForm.description" :config="ueditorConfig"></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item label="状态" prop="name">
          <el-radio-group v-model="editGoodForm.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitSecKill">保 存</el-button>
          <el-button @click="addSecondsKillDrawer=false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 添加图片广告 -->
    <el-drawer
      title="添加图片广告"
      :visible.sync="addPicAdvertisingDrawer"
      direction="rtl"
      size="500px"
      custom-class="secondsKill_drawer"
    >
      <el-form :model="editAdvForm" :rules="advRules" label-width="100px">
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="editAdvForm.title"
            placeholder="请输入标题名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="广告图片" prop="logo">
          <div>
            <label for="adv-logo" class="el-upload el-upload--picture-card">
              <input
                type="file"
                ref="advlogo"
                hidden
                id="adv-logo"
                data-type="brandLogo"
                name="upload"
                accept="image/*"
                @change="uploadImage2"
              />
              <img
                v-if="editAdvForm.logo"
                :src="editAdvForm.logo"
                style="width:142px;height:143px;"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </label>
          </div>
          <div class="ml10 form_tips">建议尺寸280 * 230px，支持JPG，PNG，JPEG格式，小于4M</div>
        </el-form-item>
        <el-form-item label="链接地址" prop="url">
          <el-input v-model="editAdvForm.url" placeholder="请输入链接地址" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model="editAdvForm.sort" placeholder="请输入排序值" maxlength="3" show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitSecKillAdv">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!--商品排序弹框-->
    <el-dialog title="排序设置" :visible.sync="dialogSortSeen" :show-close="false" center width="350px">
      <el-form label-width="40px">
        <el-form-item label="排序" style="margin:0 0 5px;" size="medium">
          <el-input
            v-model.number="editSortNum"
            type="tel"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="数值越大越靠前(范围：0-255)"
            :maxlength="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSortSeen=false">取 消</el-button>
        <el-button type="primary" @click="saveSort()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

import filterXSS from "filterXSS";
import VueUeditorWrap from "vue-ueditor-wrap";

let origin = window.location.origin;
let ueditorurl = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192.168.") ||
  window.location.hostname.includes("admin-test.youxiangzb.com")
) {
  ueditorurl = "/UEditor/";
} else {
  // ueditorurl = "https://admin.youxiangzb.com/admin/open/UEditor/";
  ueditorurl = "/UEditor/";
}
export default {
  name: "SecondsKill", // 活动-营销-秒杀设置
  components: {
    VueUeditorWrap,
  },
  props: {
    activityID: String,
  },
  data() {
    return {
      ueditorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameWidth: 700,
        initialFrameHeight: 320,
        // UEDITOR_HOME_URL: `${process.env.VUE_APP_URL}/js/ueditor1_4_3_3-utf8-net/utf8-net/`
        UEDITOR_HOME_URL: ueditorurl,
      },
      nodata: "",
      tabName: "1",
      branchViewWidth: "", // 窗体大小限制
      addSecondsKillDrawer: false, // 添加商品
      secondsKillRules: {
        productNum: [
          { required: true, message: "请输入限购数量", trigger: "blur" },
        ],
        prompt: [
          { required: true, message: "请输入秒杀提示", trigger: "blur" },
        ],
        timeRange: [
          { required: true, message: "请选择秒杀时间", trigger: "blur" },
        ],
      },
      shareRules: {
        shareTitle: [
          { required: true, message: "请输入分享标题", trigger: "blur" },
        ],
        shareDesc: [
          { required: true, message: "请输入分享描述", trigger: "blur" },
        ],
      },
      advRules: {
        title: [{ required: true, message: "请输入标题名称", trigger: "blur" }],
        logo: [{ required: true, message: "请选择广告图片", trigger: "blur" }],
        url: [{ message: "请输入链接地址", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序值", trigger: "blur" }],
      },
      addGoodRules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        logo: [{ required: true, message: "请选择商品图片", trigger: "blur" }],
        brand: [{ required: true, message: "请选择品牌", trigger: "blur" }],
        seckillPrice: [
          { required: true, message: "请输入秒杀价", trigger: "blur" },
        ],
        salePrice: [
          { required: true, message: "请输入划线价", trigger: "blur" },
        ],
        pieceUnit: [
          { required: true, message: "请输入计件单位", trigger: "blur" },
        ],
        measureUnit: [
          { required: true, message: "请输入计量单位", trigger: "blur" },
        ],
        assist: [
          { required: true, message: "请输入助力人数", trigger: "blur" },
        ],
        specs: [{ required: true, message: "请输入规格值", trigger: "blur" }],
        stock: [{ required: true, message: "请输入库存", trigger: "blur" }],
        virtualSales: [
          { required: true, message: "请输入虚拟抢购量", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序值", trigger: "blur" }],
      },
      addPicAdvertisingDrawer: false, // 添加图片广告

      // 秒杀商品参数
      SeckillList: [],
      SeckillTotal: 0,
      SeckillInfo: {
        actId: 0,
        brandId: "",
        name: "",
        page: 1,
        size: 10,
      },
      BrandList: [],
      multipleSelection: [],
      multipleSelection2: [],
      editGoodForm: {
        // actId: 0,提交时赋值
        assist: 0,
        brandId: "",
        cover: "",
        description: "",
        // id: 0,编辑点开时赋值
        measureUnit: "",
        name: "",
        pieceUnit: "",
        salePrice: 0,
        seckillPrice: 0,
        sort: 1,
        specs: "",
        status: 1,
        stock: 0,
        virtualSales: 0,
      },
      // 秒杀广告参数
      AdvList: [],
      AdvTotal: 0,
      AdvInfo: {
        actId: 0,
        page: 1,
        size: 10,
      },
      editAdvForm: {
        // actId: 0,提交时赋值
        // id: 0,编辑点开时赋值
        logo: "",
        sort: 1,
        title: "",
        url: "",
      },
      SeckillRule: {
        actId: 0,
        assistType: "one",
        endTime: "",
        logo: "",
        productNum: 0,
        prompt: "  ",
        startTime: "",
        timeRange: [],
      },
      SeckillShare: {
        actId: 0,
        shareDesc: "",
        shareLogo: "",
        shareTitle: "",
      },
      actEndTime: "",
      activityDetail: {},
      dialogSortSeen: false,
      editSortNum: 0,
      editGoodId: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.editGoodForm.cover = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传
    async uploadImage2(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.editAdvForm.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传
    async uploadImage3(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.SeckillRule.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传
    async uploadImage4(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.SeckillShare.shareLogo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 秒杀商品列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 秒杀广告列表多选
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
    },
    delConfirm() {},
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},

    // 秒杀商品列表页码请求
    handleCurrentChange(val) {
      this.SeckillInfo.page = val;
      this.getSeckillProductList();
    },
    searchPage() {
      this.SeckillInfo.page = 1;
      this.getSeckillProductList();
    },
    // 秒杀广告列表页码请求
    handleCurrentChangeAdv(val) {
      this.AdvInfo.page = val;
      this.getSeckillAdvList();
    },
    //切换tab方法
    handleClick() {
      var self = this;
      if (self.tabName == "3") {
        self.SeckillInfo = {
          actId: 0,
          brandId: "",
          name: "",
          page: 1,
          size: 10,
        };
        self.getSeckillProductList();
      }
      if (self.tabName == "2") {
        self.AdvInfo = {
          actId: 0,
          page: 1,
          size: 10,
        };
        self.getSeckillAdvList();
      }
      if (self.tabName == "1" || self.tabName == "4") {
        self.SeckillRule = {
          actId: 0,
          assistType: "one",
          endTime: "",
          logo: "",
          productNum: 0,
          prompt: "",
          startTime: "",
          timeRange: [],
        };
        self.getSeckillRule();
      }
    },

    // 秒杀商品列表
    async getSeckillProductList() {
      try {
        this.SeckillInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await MarketingAjax.getSeckillProductList(this.SeckillInfo);
        this.SeckillList = list;
        this.SeckillTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const {
          data: { list },
        } = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        this.BrandList = list;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 上架商品确认
    ConfirmSetOnline(Id) {
      var self = this;
      this.$confirm("确认上架商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.setSeckillProductOnline(Id);
        })
        .catch(() => {});
    },
    async setSeckillProductOnline(Id) {
      await MarketingAjax.setSeckillProductOnline(Id);
      await this.getSeckillProductList();
      this.$message.success("上架成功");
    },
    // 下架商品确认
    ConfirmSetOffline(Id) {
      var self = this;
      this.$confirm("确认下架商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setSeckillProductOffline(Id);
        })
        .catch(() => {});
    },
    async setSeckillProductOffline(Id) {
      await MarketingAjax.setSeckillProductOffline(Id);
      await this.getSeckillProductList();
      this.$message.success("下架成功");
    },
    // 删除商品确认
    ConfirmDelSeckillProduct(Id) {
      var self = this;
      this.$confirm("确认删除商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delSeckillProduct(Id);
        })
        .catch(() => {});
    },
    async delSeckillProduct(Id) {
      await MarketingAjax.delSeckillProduct(Id);
      await this.getSeckillProductList();
      this.$message.success("删除成功");
    },
    // 批量上架商品
    SetOnline() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("确认上架已选商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "false") {
              ids.push(item.id);
            }
          }
          if (ids.length > 0) {
            var query = ids.toString();
            this.setSeckillProductOnline(query);
          } else {
            this.$message.warning("请选择已下架的商品");
          }
        })
        .catch(() => {});
    },
    // 批量下架商品
    SetOffline() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("确认下架已选商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "true") {
              ids.push(item.id);
            }
          }
          if (ids.length > 0) {
            var query = ids.toString();
            this.setSeckillProductOffline(query);
          } else {
            this.$message.warning("请选择已上架的商品");
          }
        })
        .catch(() => {});
    },
    // 批量删除商品
    DelSeckillProduct() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("确认删除已选商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "false") {
              ids.push(item.id);
            }
            if (item.status == "true") {
              this.$message.warning(
                "选择的商品中含有已上架的商品，请先下架后再删除"
              );
              return;
            }
          }
          var query = ids.toString();
          this.delSeckillProduct(query);
        })
        .catch(() => {});
    },
    // 进入秒杀编辑或新增秒杀商品抽屉
    async ToSaveOrEditSecKill(type, row) {
      this.editGoodForm = {
        // actId: 0,提交时赋值
        assist: 0,
        brandId: "",
        cover: "",
        description: "",
        // id: 0,编辑点开时赋值
        measureUnit: "",
        name: "",
        pieceUnit: "",
        salePrice: 0,
        seckillPrice: 0,
        sort: 0,
        specs: "",
        status: 1,
        stock: 0,
        virtualSales: 0,
      };
      if (type == 1) {
        const data = await MarketingAjax.getSeckillProduct(row.id);
        var detail = data.data;

        this.editGoodForm.id = detail.id;
        this.editGoodForm.name = detail.name;
        this.editGoodForm.cover = detail.cover;
        this.editGoodForm.brandId = Number(detail.brandId);
        this.editGoodForm.seckillPrice = Number(detail.seckillPrice);
        this.editGoodForm.salePrice = Number(detail.salePrice);
        this.editGoodForm.pieceUnit = detail.pieceUnit;
        this.editGoodForm.measureUnit = detail.measureUnit;
        this.editGoodForm.assist = Number(detail.assist);
        this.editGoodForm.specs = detail.specs;
        this.editGoodForm.stock = Number(detail.stock);
        this.editGoodForm.virtualSales = Number(detail.virtualSales);
        this.editGoodForm.sort = Number(detail.sort);
        this.editGoodForm.description = detail.description;
        if (detail.status == "true") {
          this.editGoodForm.status = 1;
        } else {
          this.editGoodForm.status = 0;
        }
        // this.editBrandTitle = "编辑商品";
      } else if (type == 0) {
        if (!this.SeckillRule.startTime && !this.SeckillRule.startTime) {
          this.$message.warning("请先设置秒杀规则再新建秒杀商品");
          return;
        }
      }
      this.addSecondsKillDrawer = true;
    },

    // 确认新建或编辑秒杀商品
    async submitSecKill() {
      var self = this;
      try {
        this.editGoodForm.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.editGoodForm));
        if (act.status == 0) {
          act.status = false;
        } else {
          act.status = true;
        }

        // 验证数据
        if (this.isNullOrEmpty(act.name)) {
          this.$message.warning("商品名不能为空");
          return;
        }
        if (act.name.length > 20) {
          this.$message.warning("商品名最多20个字符");
          return;
        }
        if (!act.cover) {
          this.$message.warning("请添加商品图");
          return;
        }
        if (!act.brandId) {
          this.$message.warning("请选择品牌");
          return;
        }
        if (this.isNullOrEmpty(act.seckillPrice)) {
          this.$message.warning("秒杀价不能为空");
          return;
        }
        if (act.seckillPrice <= 0) {
          this.$message.warning("秒杀价不能小于0");
          return;
        }
        if (act.seckillPrice > 99999999) {
          this.$message.warning("秒杀价不能大于99999999");
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(act.seckillPrice) == false) {
          this.$message.warning("请输入正确秒杀价，最多两位小数");
          return;
        }
        if (this.isNullOrEmpty(act.salePrice)) {
          this.$message.warning("划线价不能为空");
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(act.salePrice) == false) {
          this.$message.warning("请输入正确划线价，最多两位小数");
          return;
        }
        if (act.salePrice <= 0) {
          this.$message.warning("划线价不能小于0");
          return;
        }
        if (act.salePrice > 99999999) {
          this.$message.warning("划线价不能大于99999999");
          return;
        }
        if (!act.pieceUnit) {
          this.$message.warning("请输入计件单位");
          return;
        }
        if (!act.measureUnit) {
          this.$message.warning("请输入计量单位");
          return;
        }
        if (this.isNullOrEmpty(act.assist)) {
          this.$message.warning("助力人数不能为空");
          return;
        }
        if (!/(^[1-9]\d*$)/.test(act.assist)) {
          if (act.assist == 0) {
          } else {
            this.$message.warning("助力人数需为整数且大于等于0");
            return;
          }
        }
        if (act.assist > 99999999) {
          this.$message.warning("助力人数不能大于99999999");
          return;
        }
        if (!act.specs) {
          this.$message.warning("请输入规格值");
          return;
        }
        if (this.isNullOrEmpty(act.stock)) {
          this.$message.warning("库存不能为空");
          return;
        }
        if (act.stock != 0 && !/(^[1-9]\d*$)/.test(act.stock)) {
          this.$message.warning("库存需为正整数");
          return;
        }
        if (act.stock > 99999999) {
          this.$message.warning("库存不能大于99999999");
          return;
        }
        if (this.isNullOrEmpty(act.virtualSales)) {
          this.$message.warning("虚拟抢购量不能为空");
          return;
        }
        if (!/(^[1-9]\d*$)/.test(act.virtualSales)) {
          if (act.virtualSales == 0) {
          } else {
            this.$message.warning("虚拟抢购量需为整数且大于等于0");
            return;
          }
        }
        if (act.virtualSales > 99999999) {
          this.$message.warning("虚拟抢购量不能大于99999999");
          return;
        }
        if (this.isNullOrEmpty(act.sort)) {
          this.$message.warning("排序值不能为空");
          return;
        }
        if (!/(^[1-9]\d*$)/.test(act.sort)) {
          if (act.sort == 0) {
          } else {
            this.$message.warning("排序值需为整数且大于等于0");
            return;
          }
        }
        if (act.sort > 255 || act.sort < 0) {
          this.$message.warning("排序值为0-255");
          return;
        }

        console.log(act);
        await MarketingAjax.saveSeckillProduct(act);
        if (this.editGoodForm.id) {
          this.$message.success("编辑秒杀商品成功");
        } else {
          this.$message.success("新建秒杀商品成功");
        }
        await this.getSeckillProductList();
        this.addSecondsKillDrawer = false;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出秒杀商品表格
    async exportData() {
      try {
        const data = await MarketingAjax.ExportSeckillProductList(
          this.activityID
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "秒杀商品列表.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 秒杀广告列表
    async getSeckillAdvList() {
      try {
        this.AdvInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await MarketingAjax.getSeckillAdvList(this.AdvInfo);
        this.AdvList = list;
        this.AdvTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 删除秒杀广告确认
    ConfirmDelSeckillAdv(Id) {
      var self = this;
      this.$confirm("确认删除秒杀广告？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delSeckillAdv(Id);
        })
        .catch(() => {});
    },
    async delSeckillAdv(Id) {
      await MarketingAjax.delSeckillAdv(Id);
      await this.getSeckillAdvList();
      this.$message.success("删除成功");
    },
    // 批量删除秒杀广告
    DelSeckillAdv() {
      var self = this;
      if (this.multipleSelection2.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("确认删除已选秒杀广告？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection2.length; i++) {
            var item = self.multipleSelection2[i];
            if (item) {
              ids.push(item.id);
            }
          }
          var query = ids.toString();
          this.delSeckillAdv(query);
        })
        .catch(() => {});
    },
    // 进入秒杀广告编辑或新增抽屉
    async ToSaveOrEditSecKillAdv(type, row) {
      this.editAdvForm = {
        // actId: 0,提交时赋值
        // id: 0,编辑点开时赋值
        logo: "",
        sort: 1,
        title: "",
        url: "",
      };
      if (type == 1) {
        this.editAdvForm.id = row.id;
        this.editAdvForm.logo = row.logo;
        this.editAdvForm.title = row.title;
        this.editAdvForm.url = row.url;
        this.editAdvForm.sort = Number(row.sort);
        // this.editBrandTitle = "编辑商品";
      }
      this.addPicAdvertisingDrawer = true;
    },
    // 确认新建或编辑秒杀广告
    async submitSecKillAdv() {
      this.editAdvForm.actId = this.activityID;
      let act = JSON.parse(JSON.stringify(this.editAdvForm));
      // 验证数据
      if (this.isNullOrEmpty(act.title)) {
        this.$message.warning("标题名不能为空");
        return;
      }
      if (!act.logo) {
        this.$message.warning("请添加广告图片");
        return;
      }
      if (this.isNullOrEmpty(act.sort)) {
        this.$message.warning("排序值不能为空");
        return;
      }
      if (!/(^[1-9]\d*$)/.test(act.sort)) {
        this.$message.warning("排序值需为正整数");
        return;
      }
      if (act.sort > 255 || act.sort < 0) {
        this.$message.warning("排序值为0-255");
        return;
      }

      console.log(act);
      await MarketingAjax.saveSeckillAdv(act);
      if (this.editAdvForm.id) {
        this.$message.success("编辑秒杀广告成功");
      } else {
        this.$message.success("新建秒杀广告成功");
      }
      await this.getSeckillAdvList();
      this.addPicAdvertisingDrawer = false;
    },
    // 秒杀规则和分享信息获取
    async getSeckillRule() {
      try {
        const result = await MarketingAjax.getSeckillRule(this.activityID);
        var detail = result.data;
        console.log(detail);
        // this.SeckillRule = {
        //   actId: this.activityID,
        //   assistType: detail.assistType,
        //   endTime: detail.endTime,
        //   logo: detail.logo,
        //   productNum: Number(detail.productNum),
        //   prompt: detail.prompt,
        //   startTime: detail.startTime
        // };
        if (detail.startTime && detail.startTime) {
          this.SeckillRule.timeRange = [];
          this.SeckillRule.timeRange.push(detail.startTime, detail.endTime);
        }
        this.SeckillRule = Object.assign(this.SeckillRule, detail);

        this.SeckillShare = {
          actId: this.activityID,
          shareDesc: detail.shareDesc,
          shareLogo: detail.shareLogo,
          shareTitle: detail.shareTitle,
        };
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑秒杀规则
    async submitSecKillRule() {
      try {
        this.SeckillRule.actId = this.activityID;
        let act = this.SeckillRule;
        if (!act.timeRange) {
          this.$message.warning("请选择秒杀时间"); // 弹出提示
          return;
        }

        if (act.timeRange && act.timeRange.length > 0) {
          act.startTime = act.timeRange[0];
          act.endTime = act.timeRange[1];
          // delete act.timeRange;
        } else {
          act.startTime = "";
          act.endTime = "";
        }
        var oldTime = new Date(act.timeRange[1]).getTime(); //得到毫秒数
        var nowTime = new Date(this.actEndTime).getTime();
        if (nowTime < oldTime) {
          this.$message.warning("秒杀结束时间不可晚于活动结束时间"); // 弹出提示
          return;
        }
        // return;
        console.log(act);
        await MarketingAjax.saveSeckillRule(act);
        this.$message.success("编辑秒杀规则成功");
        await this.getSeckillRule();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑分享设置
    async submitSecKillShare() {
      try {
        this.SeckillShare.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.SeckillShare));

        // 验证数据
        if (this.isNullOrEmpty(act.shareLogo)) {
          this.$message.warning("分享封面不能为空");
          return;
        }
        if (this.isNullOrEmpty(act.shareTitle)) {
          this.$message.warning("分享标题不能为空");
          return;
        }
        if (this.isNullOrEmpty(act.shareDesc)) {
          this.$message.warning("分享描述不能为空");
          return;
        }
        console.log(act);
        await MarketingAjax.saveSeckillShare(act);
        this.$message.success("编辑分享设置成功");
        await this.getSeckillRule();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 判断是否数字
    judgeIsNum(value) {
      console.log(value, typeof value);
      if (typeof value == "string") {
        if (Number(value)) {
          return true;
        } else {
          return false;
        }
      } else if (typeof value == "number") {
        return true;
      }
    },
    // 获取活动信息
    async getActivityEndTime() {
      try {
        var obj = JSON.parse(JSON.stringify(this.SeckillRule));
        console.log(obj);
        const { data } = await ActivityAjax.getActivityDetails(this.activityID);
        this.SeckillRule.timeRange = [];
        this.SeckillRule.timeRange = [data.actStartTime, data.actEndTime];
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getActivityDetails() {
      try {
        const res = await ActivityAjax.getActivityDetails(this.activityID);
        if (res.data && res.code == 0) {
          this.actEndTime = res.data.actEndTime;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    editSort(row) {
      this.dialogSortSeen = true;
      this.editSortNum = row.sort;
      this.editGoodId = row.id;
    },
    async saveSort() {
      try {
        if (this.editSortNum>255||this.editSortNum<0) {
          this.$message.warning("排序值需为0-255");
          return;
        }
        const res = await MarketingAjax.updateSeckillProductSort({
          id:Number(this.editGoodId),
          sort:Number(this.editSortNum),
        });
        if (res.data && res.code == 0) {
          this.$message.success(res.msg);
          this.getSeckillProductList();
          this.dialogSortSeen = false;
        }else{
          this.$message.warning(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    // this.getSeckillProductList();
    this.getActivityBrandList();
    // 记得测试时注释掉，用tab切换加载其他tab的方法
    // this.getSeckillAdvList();
    this.getSeckillRule();
    this.getActivityDetails();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>
<style lang="scss">
.SecondsKill_wrapper {
  .up_poster {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-upload--text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 178px;
        height: 178px;
      }
    }
  }
  .el-tabs__content {
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      div {
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        .el-select {
          // width: 150px !important;
        }
        .el-input {
          width: 200px;
        }
      }
    }
    .secondsKill_table {
      margin-top: 20px;
      .el-table__body {
        .cell {
          span {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .tab-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
  // 添加商品
  .secondsKill_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .el-drawer__body {
      padding: 0 20px;
      overflow-y: auto;
    }
  }

  .el-upload--text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 178px;
    height: 178px;
  }
  .form_tips {
    color: #888;
    font-size: 12px;
  }
}
</style>
